"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        $('.Testimonial__pad0').matchHeight();

        $('.menu-item-has-children').click(function(){
          // return false;
        });

        if ($(window).width() >= 768) {
          $('.menu-item-has-children').hover(function(){
            $(this).find('.nav-dropdown').toggle();
          });
        }

        $('.no-click > a').click(function(){
          return false;
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'getuigenissen': {
      init: function () {

        var list = $('.Testimonials .row');
        var listItems = list.children('div');
        list.append(listItems.get().reverse());

        var delay = (function(){
          var timer = 0;
          return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
          };
        })();


        $('.js-search').keyup(function () {

          var sSearch = this.value;
          sSearch = sSearch.split(" ");

          delay(function() {

            $('.Testimonial__pad0').removeClass('grey-color').hide();

            $.each(sSearch, function (i) {
              $.each($('.Testimonial__pad0'), function(){
                if($(this).text().match(new RegExp(sSearch[i], "ig"))){
                  $(this).show();
                }
              });
            });

            $('.Testimonial__pad0:visible').matchHeight();

            $('.Testimonial__pad0:visible').each(function(i){
              if(i % 2 === 0){
                $(this).addClass('grey-color');
              }
            });

          }, 200);


        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
